<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="saveQuickTask" v-if="onProgress === false">
      <div class="row">
        <div class="col-12">
          <template v-if="!this.is_personal_task && !this.is_branch_task">
            <div class="row mb-1" v-if="client">
              <div class="col-xl-6 col-md-12">
                <a :href="'/client/' + client.id + '/details'" class="label label-success pointer" target="_blank">
                  <i class="fas fa-user-circle"></i>
                  Существующий клиент: ID <strong>{{ client.id }}, {{ client.name }}</strong> ({{ client.source_type }})
                </a>
              </div>
              <div class="col-xl-6 col-md-12"></div>
            </div>
            <div class="row mb-1" v-else>
              <div class="col-xl-6 col-md-12">
                  <span class="label label-primary">
                    <i class="fas fa-user-circle"></i>
                    Новый клиент
                  </span>
              </div>
              <div class="col-xl-6 col-md-12"></div>
            </div>

            <div class="row">
              <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('client_name') }">
                <div class="form-group">
                  <label for="client_name">
                    <input class="form-control"
                           :disabled="Boolean(form.client_id)"
                           id="client_name"
                           name="client_name"
                           placeholder="ФИО"
                           required
                           title="ФИО клиента"
                           type="text"
                           v-model="form.client_name">
                    <HasError :form="form" field="client_name" />
                  </label>
                </div>
              </div>

              <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('client_phone') }">
                <div class="form-group">
                  <label for="client_phone">
                    <vue-tel-input id="client_phone"
                                   :disabled="Boolean(form.client_id)"
                                   v-on:input="checkPhoneOnExist"
                                   name="client_phone"
                                   v-bind="phone_config"
                                   v-model="form.client_phone">
                    </vue-tel-input>
                    <HasError :form="form" field="client_phone" />
                  </label>
                </div>
              </div>
            </div>
          </template>

          <div class="row" v-if="cars.length !== 0">
            <hr class="mt-1 mb-3" />

            <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('car_id') }">
              <div class="form-group">
                <label for="car_id">
                  <multiselect :allow-empty="true"
                               :options="cars"
                               :searchable="true"
                               :show-labels="true"
                               id="car_id"
                               @input="() => {
                                 form.car_id = car ? car.id : null;
                                 if (car && car.creator) {
                                   responsible = car.creator;
                                   form.responsible_id = car.creator.id;
                                 }
                               }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="Автомобиль"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Автомобиль"
                               track-by="id"
                               v-model="car">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                    <template slot="singleLabel" slot-scope="props">
                      #{{ props.option.id }} {{ props.option.title }}, {{ props.option.branch.title }}
                    </template>
                    <template slot="option" slot-scope="props">
                      #{{ props.option.id }} {{ props.option.title }}, <span class="text-muted">{{ props.option.branch.title }}</span>
                    </template>
                  </multiselect>
                  <HasError :form="form" field="car_id" />
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('responsible_id') }">
              <div class="form-group">

                <label for="responsible_id" class="font-medium font-small">
                  Ответственный
                </label>
                <multiselect :allow-empty="false"
                             :options="employees"
                             :searchable="false"
                             id="responsible_id"
                             @input="() => {
                               form.responsible_id = responsible ? responsible.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Ответственный"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Ответственный"
                             track-by="id"
                             v-model="responsible">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="responsible_id" />
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.is_official_employment !== 'undefined' ||
                  typeof form.details.credit_terms_year !== 'undefined' ||
                  typeof form.details.initial_fee_percent !== 'undefined'">
            <div class="col-xl-4 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.is_official_employment') }">
              <div class="form-group mt-2">
                <label>
                  Оф. трудоустройство
                  <input class="form-check-input margin-l-5" type="checkbox"
                         v-model="form.details.is_official_employment">
                  <HasError :form="form" field="details.is_official_employment" />
                </label>
              </div>
            </div>
            <div class="col-xl-4 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.credit_terms_year') }">
              <div class="form-group">
                <label>
                  <select class="form-control" v-model="form.details.credit_terms_year">
                    <option :value="null" class="text-muted">
                      Срок кредита, лет
                    </option>
                    <option :value="year"
                            v-for="year in [1, 2, 3, 4, 5]"
                            :selected="form.details.credit_terms_year === year">
                      {{ year }}
                    </option>
                  </select>
                  <HasError :form="form" field="details.credit_terms_year" />
                </label>
              </div>
            </div>
            <div class="col-xl-4 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.initial_fee_percent') }">
              <div class="form-group">
                <label>
                  Аванс <strong>{{ form.details.initial_fee_percent }}</strong>%
                  <input type="range" class="form-range" min="25" max="60" step="1"
                         v-model="form.details.initial_fee_percent">
                  <HasError :form="form" field="details.initial_fee_percent" />
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.mark !== 'undefined' ||
                  typeof form.details.model !== 'undefined'">
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('details.mark') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="true"
                               :options="marks"
                               :searchable="true"
                               @input="() => {
                                 form.details.mark = mark ? mark : null;
                               }"
                               @select="getCarModels"
                               deselect-label="Убрать"
                               label="name"
                               placeholder="Марка"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Марка"
                               track-by="id"
                               v-model="mark">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.mark" />
                </label>
              </div>
            </div>
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('details.model') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="true"
                               :disabled="models.length < 1"
                               :options="models"
                               :searchable="true"
                               @input="() => {
                               form.details.model = model ? model : null;
                             }"
                               deselect-label="Убрать"
                               label="name"
                               placeholder="Модель"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Модель"
                               track-by="id"
                               v-model="model">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.model" />
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.year !== 'undefined' || typeof form.details.race !== 'undefined' ||
                  typeof form.details.engine !== 'undefined' || typeof form.details.link !== 'undefined'">
            <div class="col-xl-3 col-md-12" v-if="typeof form.details.year !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.year') }">
              <div class="form-group">
                <label>
                  <input :max="2023"
                         :min="1900"
                         class="form-control"
                         name="year"
                         placeholder="Год выпуска"
                         title="Год выпуска"
                         type="number"
                         v-model="form.details.year">
                  <HasError :form="form" field="details.year" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-12" v-if="typeof form.details.race !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.race') }">
              <div class="form-group">
                <label>
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         name="race"
                         placeholder="Пробег"
                         title="Пробег"
                         type="number"
                         v-model="form.details.race">
                  <HasError :form="form" field="details.race" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-12" v-if="typeof form.details.engine !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.engine') }">
              <div class="form-group">
                <label>
                  <input :min="0.1"
                         class="form-control"
                         name="engine"
                         placeholder="Объем двигателя"
                         step=".1"
                         title="Объема двигателя"
                         type="number"
                         v-model="form.details.engine">
                  <HasError :form="form" field="details.engine" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-12" v-if="typeof form.details.link !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.link') }">
              <div class="form-group">
                <label>
                  <input class="form-control"
                         name="link"
                         placeholder="Ссылка на авто"
                         title="Ссылка на авто"
                         type="text"
                         v-model="form.details.link">
                  <HasError :form="form" field="details.link" />
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.year_from !== 'undefined' ||
                  typeof form.details.year_to !== 'undefined' || typeof form.details.race_from !== 'undefined' ||
                  typeof form.details.race_to !== 'undefined'">
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.year_from !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.year_from') }">
              <div class="form-group">
                <label>
                  <input :max="2023"
                         :min="1900"
                         class="form-control"
                         name="year_from"
                         placeholder="Год, от"
                         title="Год, от"
                         type="number"
                         v-model="form.details.year_from">
                  <HasError :form="form" field="details.year_from" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.year_to !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.year_to') }">
              <div class="form-group">
                <label>
                  <input :max="2023"
                         :min="1900"
                         class="form-control"
                         name="year_to"
                         placeholder="Год, до"
                         title="Год, до"
                         type="number"
                         v-model="form.details.year_to">
                  <HasError :form="form" field="details.year_to" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.race_from !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.race_from') }">
              <div class="form-group">
                <label>
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         name="race_from"
                         placeholder="Пробег, от"
                         title="Пробег, от"
                         type="number"
                         v-model="form.details.race_from">
                  <HasError :form="form" field="details.race_from" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.race_to !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.race_to') }">
              <div class="form-group">
                <label>
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         name="race_to"
                         placeholder="Пробег, до"
                         title="Пробег, до"
                         type="number"
                         v-model="form.details.race_to">
                  <HasError :form="form" field="details.race_to" />
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.color !== 'undefined' ||
                  typeof form.details.gearbox !== 'undefined' || typeof form.details.transmission !== 'undefined' ||
                  typeof form.details.price_from !== 'undefined' || typeof form.details.price_to !== 'undefined' ||
                  typeof form.details.price !== 'undefined'">
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.color !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.color') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="true"
                               :options="colors"
                               :searchable="true"
                               @input="() => {
                                 form.details.color = color ? color : null;
                               }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="Цвет"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Цвет"
                               track-by="id"
                               v-model="color">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.color" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.gearbox !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.gearbox') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="false"
                               :options="gearboxes"
                               :searchable="true"
                               @input="() => {
                                 form.details.gearbox = gearbox ? gearbox : null;
                               }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="КПП"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="КПП"
                               track-by="id"
                               v-model="gearbox">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.gearbox" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.transmission !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.transmission') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="false"
                               :options="transmissions"
                               :searchable="true"
                               @input="() => {
                                 form.details.transmission = transmission ? transmission : null;
                               }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="Привод"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Привод"
                               track-by="id"
                               v-model="transmission">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.transmission" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.price_from !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.price_from') }">
              <div class="form-group">
                <label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="price_from"
                         placeholder="Цена, от"
                         title="Цена, от"
                         type="number"
                         v-model="form.details.price_from">
                  <HasError :form="form" field="details.price_from" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.price_to !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.price_to') }">
              <div class="form-group">
                <label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="price_to"
                         placeholder="Цена, до"
                         title="Цена, до"
                         type="number"
                         v-model="form.details.price_to">
                  <HasError :form="form" field="details.price_to" />
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.price !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.price') }">
              <div class="form-group">
                <label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="price"
                         placeholder="Цена, $"
                         title="Цена, $"
                         type="number"
                         v-model="form.details.price">
                  <HasError :form="form" field="details.price" />
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.bargain_sum !== 'undefined'">
            <div class="col-xl-3 col-md-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.bargain_sum') }">
              <div class="form-group">
                <label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="bargain_sum"
                         placeholder="Сумма торга"
                         title="Сумма торга"
                         type="number"
                         v-model="form.details.bargain_sum">
                  <HasError :form="form" field="details.bargain_sum" />
                </label>
              </div>
            </div>
          </div>

          <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('comment') }">
              <textarea class="form-control"
                        maxlength="3000"
                        id="comment"
                        rows="3"
                        placeholder="Комментарий"
                        v-model="form.comment">
              </textarea>
            <HasError :form="form" field="comment" />
          </div>

          <hr />

          <div class="row">
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_at') }">
              <div class="form-group">
                <label for="term_at" class="font-medium font-small">
                  Срок выполнения
                </label>
                <v-date-picker
                  :masks="date_options.masks"
                  :max-date="date_options.maxDate"
                  :min-date="date_options.minDate"
                  :mode="'date'"
                  :model-config="date_options.formatDate"
                  :update-on-input="false"
                  color="blue"
                  locale="ru"
                  v-model="form.term_at"
                  trim-weeks>
                  <template v-slot="{inputValue, inputEvents}">
                    <input :value="inputValue"
                           class="form-control"
                           id="term_at"
                           placeholder="Дата выполнения"
                           v-on="inputEvents" />
                  </template>
                </v-date-picker>
                <HasError :form="form" field="term_at" />
              </div>
            </div>
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_time') }">
              <div class="form-group">
                <label for="term_time" class="font-medium font-small">
                  Время
                </label>
                <input type="time" class="form-control" id="term_time" name="term_time" v-model="form.term_time"
                       required>
                <HasError :form="form" field="term_time" />
              </div>
            </div>
          </div>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

          <br />

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>
        </div>
      </div>
    </form>
    <loading-processing v-else></loading-processing>
  </transition>
</template>

<script>
import Vue from "vue";
import Form from "vform";
import Multiselect from "vue-multiselect";
import { VueTelInput } from "vue-tel-input";
import phone_config from "./../../../assets/data/vue-tel-input-config.json";
import moment from "moment";
import VCalendar from "v-calendar";

Form.axios = API.apiClient;

Vue.use(VCalendar, {});

moment.locale("ru");

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  name: "create-quick-task-form",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
    VueTelInput,
  },

  props: {
    tasks_type_id: {
      type: Number,
      required: true,
    },
    with_car: {
      type: [Boolean, null],
      required: false,
      default: null,
    },
    is_personal_task: {
      type: [Boolean, null],
      required: false,
      default: null,
    },
    is_branch_task: {
      type: [Boolean, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    form: new Form({
      is_quick: true,
      client_id: null,
      client_name: null,
      client_phone: null,
      car_id: null,
      type_id: null,
      type_status_id: 2,
      details: {},
      responsible_id: null,
      comment: null,
      term_at: null,
      term_time: "00:00",
    }),

    clients: [],
    client: null,
    cars: [],
    car: null,
    employees: [],
    responsible: null,

    marks: [],
    mark: null,
    models: [],
    model: null,
    colors: [],
    color: null,
    gearboxes: [],
    gearbox: null,
    transmissions: [],
    transmission: null,

    onProgress: true,

    phone_config: phone_config,

    date_options: {
      minDate: moment().format(DATE_FORMAT),
      maxDate: moment().add(3, "M").format(DATE_FORMAT),
      formatDate: {
        type: "string",
        mask: DATE_FORMAT,
      },
      masks: {
        input: DATE_FORMAT,
      },
    },
  }),

  async created() {
    try {
      this.form.type_id = this.tasks_type_id;
      this.form.responsible_id = this.$store.getters["auth/authUser"].id;
      this.form.is_personal_task = this.is_personal_task;
      this.form.is_branch_task = this.is_branch_task;

      if (!this.is_personal_task && !this.is_branch_task) {
        await this.getClients();
      }

      if (this.with_car) {
        await this.getCars();
      }

      await this.getEmployees();

      this.responsible = this.employees.find((employee) => {
        return employee.id === this.form.responsible_id;
      });

      switch (this.tasks_type_id) {
        case 5:
          await this.getCarMarks();
          await this.getCarColors();
          await this.getCarGearboxes();
          await this.getCarTransmissions();

          this.form.details = {
            mark: null,
            model: null,
            year_from: null,
            year_to: null,
            race_from: null,
            race_to: null,
            engine: null,
            color: null,
            gearbox: null,
            transmission: null,
            price_from: null,
            price_to: null,
          };
          break;
        case 6:
          this.form.details = {
            is_official_employment: false,
            credit_terms_year: null,
            initial_fee_percent: 25,
          };
          break;
        case 7:
          await this.getCarMarks();

          this.form.details = {
            mark: null,
            model: null,
            year: null,
            race: null,
            link: null,
          };
          break;
        case 13:
          await this.getCarMarks();
          await this.getCarColors();
          await this.getCarGearboxes();
          await this.getCarTransmissions();

          this.form.details = {
            mark: null,
            model: null,
            year: null,
            race: null,
            engine: null,
            color: null,
            gearbox: null,
            transmission: null,
            link: null,
          };
          break;
        case 20:
          await this.getCarMarks();
          await this.getCarColors();
          await this.getCarGearboxes();
          await this.getCarTransmissions();

          this.form.details = {
            mark: null,
            model: null,
            year: null,
            race: null,
            engine: null,
            color: null,
            gearbox: null,
            transmission: null,
            price_from: null,
            link: null,
          };
          break;
        case 30:
          await this.getCarMarks();
          await this.getCarColors();
          await this.getCarGearboxes();
          await this.getCarTransmissions();

          this.form.details = {
            mark: null,
            model: null,
            year: null,
            race: null,
            engine: null,
            color: null,
            gearbox: null,
            transmission: null,
            price: null,
          };
          break;
      }
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function() {
      return ((!this.is_personal_task && !this.is_branch_task) ? (this.form.client_id ||
          (this.form.client_name && this.form.client_phone)) : true) && this.form.type_id &&
        this.form.type_status_id === 2 && this.form.responsible_id;
    },
  },

  methods: {
    async getClients() {
      try {
        const clients_response = await API.apiClient.get("/clients", {
          params: {
            "is_list_load": 1
          },
        });
        this.clients = clients_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCars() {
      try {
        const cars_response = await API.apiClient.get("/cars", {
          params: {
            relations: [
              "creator",
              "branch",
            ],
            filters_list: {
              "is_paginated": 0,
            },
          },
        });
        this.cars = cars_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getEmployees() {
      try {
        const employees_response = await API.apiClient.get("/employees", {
          params: this.$apiRequestParams.filters_list,
        });
        this.employees = employees_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarMarks() {
      try {
        const marks_response = await API.apiClient.get("/cars/marks", {
          params: {
            is_paginated: 0,
            sorting: "asc",
            sort_column: "name",
          },
        });
        this.marks = marks_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarModels(mark) {
      try {
        this.models = [];
        const models_response = await API.apiClient.get(
          "/cars/mark/" + mark.id + "/models", {
            params: {
              is_paginated: 0,
              sorting: "asc",
              sort_column: "name",
            },
          },
        );
        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarColors() {
      try {
        const colors_response = await API.apiClient.get("/cars/colors");
        this.colors = colors_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarGearboxes() {
      try {
        const gearboxes_response = await API.apiClient.get("/cars/gearboxes");
        this.gearboxes = gearboxes_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarTransmissions() {
      try {
        const transmissions_response = await API.apiClient.get("/cars/transmissions");
        this.transmissions = transmissions_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    checkPhoneOnExist() {
      if (this.form.client_phone.length > 12) {
        let clientPhoneClear = parseInt(this.form.client_phone.replace(/\s/g, ""));
        this.client = this.clients.find((client) => {
          return client.phone === clientPhoneClear;
        });

        if (this.client) {
          this.form.client_name = this.client.name;
        }
      }
    },

    async saveQuickTask() {
      if (this.form.term_at) {
        this.form.term_at = moment(this.form.term_at).format(DATE_FORMAT);
      }

      await this.form.post("/tasks/add").then(() => {
        showSuccess();
        this.EventBus.$emit("hide-quick-create-task-modal");
        this.EventBus.$emit("task-items-reload");
        this.EventBus.$emit("tasks-statistic-reload");
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style>
.multiselect__content-wrapper {
  position: relative;
}
</style>
